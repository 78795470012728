// CardSection.js
import React from 'react';
import { CardElement } from 'react-stripe-elements';
import './CardSection.scss'
import { injectStripe } from 'react-stripe-elements'
class CardSection extends React.Component {
  render() {
    return (
        <div className="stripe-container">
          <div className="label">Card details</div>
          <CardElement />
        </div>
    );
  }
}

export default injectStripe(CardSection);