import React, { useState, useEffect, useRef } from 'react';
import './UpgradeSubscriptionModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import UseWindowDimensions from '../Utils/UseWindowDimensions';
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard';
import { ReactSVG } from 'react-svg';

export default function UpgradeSubscriptionModal(props) {
  const [showingInfo, setShowingInfo] = useState('');
  const [businessSubscription24M, setBusinessSubscription24M] = useState({});
  const [businessSubscripton24A, setBusinessSubscripton24A] = useState({});
  const [residentialSubscripton12M, setResidentialSubscripton12M] = useState({});
  const [residentialSubscripton12A, setResidentialSubscripton12A] = useState({});
  const [residentialSubscripton24M, setResidentialSubscripton24M] = useState({});
  const [residentialSubscripton24A, setResidentialSubscripton24A] = useState({});
  const [chosenSubscription, setChosenSubscription] = useState(false);
  const [subscription, setSubscription] = useState({})
  const [chosenSubscriptionKey, setChosenSubscriptionKey] = useState(false);
  const { width } = UseWindowDimensions();
  const infoButton = useRef(null);

  useEffect(()=>{
    fetchSubscriptions()
  }, [])

  function fetchSubscriptions() {
    let url = 'http://localhost:8000/ecommerce/api/get_plans';
    fetch(url,{
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      if(props.user.type === 1) {
        console.log('data.plans', data.plans.business['24'].monthly.hub.amount);
        console.log('data.plans', data.plans.business['24'].yearly.hub.amount);
        setBusinessSubscription24M(data.plans.business['24'].monthly);
        setBusinessSubscripton24A(data.plans.business['24'].yearly);
      } else {
        setResidentialSubscripton12M(data.plans.residential['12'].monthly);
        setResidentialSubscripton12A(data.plans.residential['12'].yearly)
        setResidentialSubscripton24M(data.plans.residential['24'].monthly)
        setResidentialSubscripton24A(data.plans.residential['24'].yearly)
      }
    })
  }

  function addSubscription() {
    let url = 'http://localhost:8000/api/mobile/create_subscription';
    
    let data = {
      house: {
        house_id: "test123",
        user_email: "prav@test.com",
        name: "Samurai",
        address: {
          address1: "ticktok",
          address2: "ABC",
          city: "San Jose",
          state: "CA",
          zipcode: "95134"
        },
        total_hubs: 2,
        total_cameras: 3,
        hubs: [
          {
            hub_id: "test_hub_123",
            serial_no: "123456",
            camera_count: 2,
            is_deleted: false
          }
        ]
      },
      user_email: "prav@test.com",
      term: "M",
      monitoring: "12",
      plan_type: "R"
    }

    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(response=>{
      return response.json()
    })
    .then(data=>{
      console.log('data', data);
    })
  }

  function chooseSubscription(subscription, code) {
    if(code === chosenSubscription) {
      setChosenSubscription(false);
      return;
    }
    setSubscription(subscription)
    setChosenSubscription(code);
  }

  function editInfo() {
    props.addSubscription();
  }

  function renderCheck() {
    return (
      <ReactSVG beforeInjection={svg => {
        svg.classList.add('check')
        }}
        src={require("../assets/check_icon.svg")}
      /> 
    )
  }

  return (
    <div className="upgrade-modal-container">
      <div className="upgrade-modal-content">
        <div className="close-container">
          <img src={require('../assets/close.png')} alt="" className="close" onClick={()=>{props.close(false)}}/>
        </div>
        <div className="modal-header">
          <div className="back-container">
            <div className="back" onClick={()=>{props.close(false)}}></div>
          </div>
          <div className="title" style={{ fontSize: '1.5em'}}>Choose Subscription</div>
        </div>
        <div className="subscriptions-container">
            {
              props.user.type === 1 && businessSubscription24M.hub ? (
                <div className="subscriptions">
                  <div style={{width: "100%"}}>
                    <SubscriptionCard subscription={businessSubscription24M} paymentCycle={'monthly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'b24m'} code='b24m' hubQty={props.hubQty} cameraQty={props.cameraQty}/>
                    <SubscriptionCard subscription={businessSubscripton24A} paymentCycle={'yearly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'b24y'} code='b24y' hubQty={props.hubQty} cameraQty={props.cameraQty}/>
                  </div>
                </div>
              ) : (
                residentialSubscripton24A.hub && (
                    <div className="subscriptions">
                      <SubscriptionCard subscription={residentialSubscripton12M} paymentCycle={'monthly'} monitoring={'12'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r12m'} code='r12m' hubQty={props.hubQty} cameraQty={props.cameraQty}/>
                      <SubscriptionCard subscription={residentialSubscripton12A} paymentCycle={'yearly'} monitoring={'12'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r12y'} code='r12y' hubQty={props.hubQty} cameraQty={props.cameraQty}/>
                      <SubscriptionCard subscription={residentialSubscripton24M} paymentCycle={'monthly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r24m'} code='r24m' hubQty={props.hubQty} cameraQty={props.cameraQty}/>
                      <SubscriptionCard subscription={residentialSubscripton24A} paymentCycle={'yearly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r24y'} code='r24y' hubQty={props.hubQty} cameraQty={props.cameraQty}/>
                    </div>
                  )
                )
              }
            <div className="plan-desc-container">
              <div className="plan-desc-title">All plans include:</div>
              <div className="details-container">
                <div className="plan-desc-detail">{renderCheck()} Motion alerts</div>
                <div className="plan-desc-detail">{renderCheck()} AI crime detection</div>
                <div className="plan-desc-detail">{renderCheck()} Emergency Contact</div>
                <div className="plan-desc-detail">{renderCheck()} Live view</div>
                <div className="plan-desc-detail">{renderCheck()} 2-way communication</div>
              </div>
            </div>
          </div>
        <div className="update-info-button-container">
          <button className="update-info-button" disabled={chosenSubscription === false} style={{backgroundColor: (chosenSubscription !== false) ? '#3F3250' : 'rgb(170, 170, 170)'}} onClick={editInfo}>Upgrade!</button>
        </div>
      </div>
    </div>
  )
}