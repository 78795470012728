import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';
import UseWindowDimensions from '../Utils/UseWindowDimensions'
function Sidebar (props){
  let sideBarClasses = 'sidebar-container';
  if (props.show) {
    sideBarClasses = 'sidebar-container open';
  }
  const [accountLinkColor, setAccountLinkColor] = useState('')
  const [billingLinkColor, setBillingLinkColor] = useState('')
  const [homeAndDevicesLinkColor, setHomeAndDevicesColor] = useState('')
  const { width } = UseWindowDimensions();

  useEffect(()=>{
    const path = window.location.pathname.replace('/', '');
    switch (path) {
      case '':
        setAccountLinkColor('white')
        break;
      case 'billing':
        setBillingLinkColor('white')
      break;
      case 'home-and-devices':
        setHomeAndDevicesColor('white')
      break;
      default:
        break;
    }
  }, []);

  function clearLinkColors() {
    setAccountLinkColor('');
    setBillingLinkColor('');
    setHomeAndDevicesColor('')
  }

  function setLinkColorState(page) {
    switch (page) {
      case 'account':
        clearLinkColors();
        setAccountLinkColor('white');
        break;
      case 'billing':
        clearLinkColors();
        setBillingLinkColor('white')
        break;
      case 'home-and-devices':
        clearLinkColors();
        setHomeAndDevicesColor('white')
        break;
      default:
        clearLinkColors();
        setAccountLinkColor('white');
        break;
    }
    props.click();
  }

  function logOut() {
    let url = `https://staging.s3ntin3l.com/mobile/app/ecustomer/v1/api/logout`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      window.localStorage.clear();
    })
  }

  return (
    <div className={sideBarClasses}>
      {
        width < 768 ? (
          <div className="header">
            <div className="close-container">
              <div className="close" onClick={props.click} />
            </div>
          </div>
        ) : (
          <div className="header-desktop">
            <img className="sidebar-logo-img" src={require("../assets/Logo_graphic@2x.png")} />
          </div>
        )
      }
      <div className="links">
        <Link to="/" style={{ color: accountLinkColor}} className="link" onClick={()=>{setLinkColorState('account')}}>
          Account
        </Link>
        <Link to="/billing" style={{ color: billingLinkColor}} className="link" onClick={()=>{setLinkColorState('billing')}}>
          Billing
        </Link>
        <Link to="/home-and-devices"  style={{ color: homeAndDevicesLinkColor}} className="link" onClick={()=>{setLinkColorState('home-and-devices')}}>
          Home & Devices
        </Link>
      </div> 
      <div className="sidebar-footer">
        <Link to="/login" style={{textDecoration: 'none'}}>
          <div className="logout" onClick={logOut}>Log Out</div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
