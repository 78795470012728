import React, { useState } from 'react';
import CardSection from '../CardSection/CardSection';
import { states, countries } from '../Utils/LocationData.js'
import './CardModal.scss';
import { injectStripe } from 'react-stripe-elements'
function CardModal(props) {
  const [name, setName] = useState(props.card.name || '');
  const [address_line1, setAddress_1] = useState(props.card.address_line1 || '');
  const [address_line2, setAddress_2] = useState(props.card.address_line2 || '');
  const [address_city, setCity] = useState(props.card.address_city || '');
  const [address_state, setState] = useState(props.card.address_state || '');
  const [address_country, setCountry] = useState(props.card.address_country || '');
  const [address_zip, setZipcode] = useState(props.card.address_zip || '');
  const [exp_month, setMonth] = useState(props.card.exp_month || '');
  const [exp_year, setYear] = useState(props.card.exp_year || '');
  const [expDateError, setExpDateError] = useState('')
  // const [invalidExpDateError, setInvalidExpDateError] = useState('');
  const [markDefault, setMarkDefault] = useState(props.card.default_source || false);
  function addCard() {
    let data = {
      name,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_country,
      address_zip
    };
    props.addCard(data);
    setTimeout(() => {
      props.close(false);
    }, 1000);
  }

  function setDefaultCard() {
    let data = {
      stripeId: 'cus_GSPzeMQbl0VRBh',
      cardId: props.card.id
    };
    if(props.card.name) {
      if(window.confirm(`Set ${props.card.name}'s card ending in ${props.card.last4} to the default card?`)) {
        props.setAsDefault(data);
      }
    } else {
      if(window.confirm(`Set this card to the default card?`)) {
        props.setAsDefault(data);
      }
    }
  }

  function deleteCard() {
    let data = {cardId: props.card.id, stripeId: 'cus_GSPzeMQbl0VRBh'}
    if(!props.card.default_source) {
      if(window.confirm(`are you sure you want to delete ${props.card.name + "'s" || "No Name's"} card?`)){
        props.deleteCard(data);
        props.close(false);
      }
    } else {
      alert('Card cannot be deleted because it is the default source.');
    }
  }

  function updateCard() {
    const currentYear = new Date().getFullYear().toString().substr(-2);
    const currentMonth = new Date().getMonth().toString().substr(-2);
    let data = {
      fields: {
        name,
        address_line1,
        address_line2,
        address_city,
        address_state,
        address_country,
        address_zip,
        exp_month,
        exp_year
      },
      card_id: props.card.id
    };

    if(exp_year.toString().substr(-2) > currentYear) {
      setExpDateError('Invalid expire date');
      if(markDefault && !props.card.default_source) {
        setDefaultCard();
      }
      props.close(false);
      props.updateCard(data);
    } else {
      if ((exp_month.toString().substr(-2) >= currentMonth && exp_month.toString().substr(-2) <= 12) && exp_year.toString().substr(-2) >= currentYear) {
        props.updateCard(data);
        if(markDefault && !props.card.default_source) {
          setDefaultCard();
        }
        props.close(false);
      } else {
        setExpDateError('Invalid expire date');
      }
    }
  }

  function closeModal() {
    if(props.action === 'add') {
      props.close(false);
    } else if(props.action === 'update') {
      props.close({});
    }
  }

  return (
        <div className="modal-container">
          <div className="modal-content">
            <div className="close-container">
              <img src={require('../assets/close.png')} alt="" className="close" onClick={closeModal}/>
            </div>
            <div className="modal-header">
            <div className="back-container">
              <div className="back" onClick={closeModal}></div>
            </div>
              <div className="title">Card Info</div>
            </div>
            {expDateError && <div className="error">{expDateError}</div>}
            <div className="inputs-container">
              <div className="input-container">
                <div className="label">Name</div>
                <input type="text" placeholder="" className="input" value={name} onChange={e => setName(e.target.value)}/>
              </div>
              <div className="divided-inputs">
                <div className="input-container-divided">
                  <div className="label">Address 1</div>
                  <input type="text" value={address_line1} onChange={e => setAddress_1(e.target.value)} className="input"/>
                </div>
                <div className="input-container-divided">
                  <div className="label">Address 2</div>
                  <input type="text" value={address_line2} onChange={e => setAddress_2(e.target.value)} className="input"/>
                </div>
              </div>
              <div className="divided-inputs">
                <div className="input-container-divided">
                  <div className="label">City</div>
                    <input type="text" value={address_city} onChange={e => setCity(e.target.value)} className="input" />
                  </div>
                <div className="input-container-divided">
                  <div className="label">State:</div>
                  <select value={address_state} onChange={e => setState(e.target.value)}>
                    {states.map((state, key) => {
                      return <option key={key} value={state}>{state}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="divided-inputs">
                <div className="input-container-divided">
                  <div className="label">Country</div>
                  <select value={address_country} onChange={e => setCountry(e.target.value)}>
                    {countries.map((country, key) => {
                      return <option key={key} value={country.code}>{country.name}</option>;
                    })}
                  </select>
                </div>
                <div className="input-container-divided">
                  <div className="label">Zipcode</div>
                  <input maxLength="5" type="text" value={address_zip} onChange={e => setZipcode(e.target.value)} className="input"/>
                </div>
              </div>
              <div className="divided-inputs">
                {props.action === 'add' && <CardSection />}
              </div>
              {props.action === 'update' && (
                <div className="divided-inputs">
                  <div className="input-container-divided">
                    <div className="label">Exp Month:</div>
                    <input maxLength="2" type="text" value={exp_month} onChange={e => setMonth(e.target.value)} className="input"/>
                  </div>
                  <div className="input-container-divided">
                    <div className="label">Exp Year:</div>
                    <input maxLength="4" type="text" value={exp_year} onChange={e => setYear(e.target.value)} className="input"/>
                  </div>
                </div>
              )}
              {
                !props.card.default_source && props.action !== 'add' && (
                  <div className="set-default-card">
                    <div className="box-container" onClick={()=>{setMarkDefault(!markDefault)}}><div className="box" style={{backgroundColor: markDefault ? '#353250' : 'white'}}></div></div>
                    <div className="default-title">Set Primary</div>
                  </div>
                )
              }
            </div>
            {props.action === 'add' ? (
              <div className="update-info-button-container">
                  <div className="update-info-button" onClick={() => {addCard();}}>Add Card</div>
                </div>
                ) : (
                  <div className="actions-container">
                  <div className="card-button-delete" onClick={()=>{deleteCard()}}>Delete Card</div>
                  <div className="card-button" onClick={() => {updateCard();}}>Update Card</div>
                </div>
              )}
          </div>
        </div>
  );
}

export default injectStripe(CardModal);
