import React from 'react';
import './Footer.scss'
import UseWindowDimensions from '../Utils/UseWindowDimensions'
export default function Footer(props) {
  const { width } = UseWindowDimensions();
  return (
    <div className="footer" style={{display: (props.hidden && width < 768  ? 'none' : 'block')}}>
      <div className="need-help-title">Need help?</div>
      <div className="contact">Contact us at <a className="phone" href="tel:1-833-983-6006">833.983.6006</a></div>
    </div>
  )
}