import React, { useState } from 'react';
import './AccountInfoModal.scss';
export default function AccountInfoModal(props) {
  const [customerFirstName, setCustomerFirstName] = useState(props.account.first_name);
  const [customerLastName, setCustomerLastName] = useState(props.account.last_name);
  const [customerPhone, setCustomerPhone] = useState(props.account.mobile_phone);
  const [customerEmail, setCustomerEmail] = useState(props.account.email_address);


  function editInfo() {
    let data = {
      first_name: customerFirstName,
      last_name: customerLastName,
      mobile_phone: customerPhone,
      email: customerEmail,
      ds_customer_id: props.account.ds_customer_id,
    }
    props.updateUserData(data);
    props.close(false, 'accountInfo');
  }

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="close-container">
          <img src={require('../assets/close.png')} alt="" className="close" onClick={()=>{props.close(false, 'accountInfo')}}/>
        </div>
        <div className="modal-header">
          <div className="back-container">
            <div className="back" onClick={()=>{props.close(false, 'accountInfo')}}></div>
          </div>
          <div className="title">Account Info</div>
        </div>
        <div className="inputs-container">
          <div className="name-inputs-container">
            <div className="name-input-container">
              <div className="label">First Name</div>
              <input type="text" placeholder="" className="name-input" value={customerFirstName} onChange={e => setCustomerFirstName(e.target.value)}/>
            </div>
            <div className="name-input-container">
              <div className="label">Last Name</div>
              <input type="text" placeholder="" className="name-input" value={customerLastName} onChange={e => setCustomerLastName(e.target.value)}/>
            </div>
          </div>
          <div className="input-container">
            <div className="label">Phone</div>
            <input type="text" placeholder="" className="input" value={customerPhone} onChange={e => setCustomerPhone(e.target.value)}/>
          </div>
          <div className="input-container">
            <div className="label">Email</div>
            <input type="text" placeholder="" className="input" value={customerEmail} onChange={e => setCustomerEmail(e.target.value)}/>
          </div>
        </div>
        <div className="update-info-button-container">
          <div className="update-info-button" onClick={editInfo}>Update Info</div>
        </div>
      </div>
    </div>
  )
}